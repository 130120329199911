import { Button, Col, Container, Row } from 'react-bootstrap'
import { ReactNode } from 'react'

import styles from './ContentCenter.module.scss'

export type ContentCenterProps = {
    halfTitle?: string
    title: string
    desc?: string
    customClass?: string
    ContentCenterChild?: ReactNode
    colWidth?: number
    linkButton?: { label: string; link: string; variant: string; target?: string }
    linkButton2?: { label: string; link: string; variant: string; target?: string }
}

export default function ContentCenter({
    halfTitle,
    title,
    desc,
    linkButton,
    linkButton2,
    customClass,
    colWidth,
    ContentCenterChild,
}: ContentCenterProps) {
    const componentClass = `ContentCenter ${styles.ContentCenter} ${customClass ?? ''}`

    return (
        <section className={componentClass}>
            <Container>
                {halfTitle && <p className="half-title">{halfTitle}</p>}
                <h2 dangerouslySetInnerHTML={{ __html: title ?? '' }} className="section-title-h2 h1 mb-5"></h2>
                <Row className="mb-5 mb-lg-7">
                    <Col md={colWidth ? colWidth : 12}>
                        {desc && <p className={linkButton && 'mb-5'}>{desc}</p>}

                        {linkButton && (
                            <Button
                                className={linkButton2 && 'me-3 mb-3 mb-lg-0'}
                                href={linkButton.link}
                                variant={linkButton.variant}
                                target={linkButton.target ?? '_self'}
                            >
                                {linkButton.label}
                            </Button>
                        )}

                        {linkButton2 && (
                            <Button
                                href={linkButton2.link}
                                variant={linkButton2.variant}
                                target={linkButton2.target ?? '_self'}
                            >
                                {linkButton2.label}
                            </Button>
                        )}
                    </Col>
                    <Col md={5}></Col>
                </Row>
                <div>{ContentCenterChild}</div>
            </Container>
        </section>
    )
}
