import { Col } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './Accordion.module.scss'

type ItemsProps = {
    title?: string
    desc?: string
}

export type AccordionComponentDefaultProps = {
    sectionTitle?: string
    customClass?: string
    alwaysOpen?: boolean
    items: ItemsProps[]
    colDesc?: number
}

export default function AccordionComponent({
    sectionTitle,
    customClass,
    items,
    alwaysOpen,
    colDesc,
}: AccordionComponentDefaultProps) {
    const componentClass = `AccordionComponent ${styles.AccordionComponent} ${customClass ?? ''}`

    return (
        <section className={componentClass}>
            <h2 className="mb-5" dangerouslySetInnerHTML={{ __html: sectionTitle ?? '' }}></h2>
            <Accordion alwaysOpen={alwaysOpen}>
                {items?.map((item: ItemsProps, index: number) => (
                    <FramerMotion key={index}>
                        <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body>
                                <Col lg={colDesc ?? 10} dangerouslySetInnerHTML={{ __html: item.desc ?? '' }}></Col>
                            </Accordion.Body>
                        </Accordion.Item>
                    </FramerMotion>
                ))}
            </Accordion>
        </section>
    )
}
