import { ReactNode, useEffect } from 'react'

import ListLoader from '@atk/components/loaders/ListLoader/ListLoader'

import useHotspotForm from '@atk/hooks/useHotspotForm'

type HubspotFormProps = {
    formId: string
    target: string
    children: ReactNode
    onFormSubmitted?: (value?: boolean) => void
}

export default function HubspotForm({ formId, target, children, onFormSubmitted }: HubspotFormProps) {
    const { loading, initHotspotForm, show } = useHotspotForm({
        region: 'na1',
        portalId: '6073987',
        formId,
        target,
        autohide: true,
    })
    useEffect(() => {
        const timerHubspot = setTimeout(() => {
            initHotspotForm()
        }, 1000)
        return () => {
            clearTimeout(timerHubspot)
        }
    }, [])

    useEffect(() => {
        if (onFormSubmitted) onFormSubmitted(show)
    }, [onFormSubmitted, show])

    return (
        <>
            {loading && <ListLoader />}
            <div className="formContainer">{children}</div>
        </>
    )
}
