const scrollObjFromBody = (el: string) => {
    const obj = document.getElementById(el)
    if (obj) {
        obj.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        })
    }
}

const scrollToTargetAdjusted = (el: string, offset: number) => {
    const obj = document.getElementById(el)
    if (obj) {
        const elementPosition = obj.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - offset
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        })
    }
}

const scrollObjFromAnchorWithRetry = (route: string, repeat: number, offset?: number) => {
    scrollObjFromAnchor(route, offset)
    for (let index = 1; index < repeat; index++) {
        const timer = setTimeout(() => {
            scrollObjFromAnchor(route, offset)
            if (timer) clearTimeout(timer)
        }, 1000 * index)
    }
}

const scrollObjFromAnchor = (route: string, offset?: number) => {
    const splittedRoute = route.split('#')
    if (splittedRoute.length == 2) {
        if (offset) {
            scrollToTargetAdjusted(splittedRoute[1], offset)
        } else {
            scrollObjFromBody(splittedRoute[1])
        }
    }
}

export { scrollObjFromBody, scrollObjFromAnchor, scrollToTargetAdjusted, scrollObjFromAnchorWithRetry }
