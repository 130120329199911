import { Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'

import HubspotForm from '@atk/components/layout/Modal/HubspotForm'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './Newsletter2.module.scss'

export type newsLetter2Props = {
    title: string
    desc?: string
    col1?: number
    col2?: number
    customClass?: string
}

export default function Newsletter2({ title, desc, col1, col2, customClass }: newsLetter2Props) {
    const componentClass = `newsLetter2 ${styles.newsLetter2} ${customClass ?? ''}`
    const [submitted, setSubmitted] = useState(false)
    const onFormSubmitted = (value?: boolean) => {
        setSubmitted(value ?? false)
    }
    return (
        <section className={componentClass}>
            <FramerMotion y={100}>
                <Container>
                    <Row className="align-items-center">
                        <Col md={col1 ? col1 : 6} className={styles.title}>
                            <p className="h3 mb-4 mb-lg-0">{title}</p>
                            <p className="mb-4 mb-lg-0">{desc}</p>
                        </Col>
                        <Col md={col2 ? col2 : 6}>
                            <div className="position-relative">
                                <HubspotForm
                                    formId="1f65e0fe-b2be-4ee0-986d-ac8f8821f529"
                                    target="#getCTANewsletterHubspot"
                                    onFormSubmitted={onFormSubmitted}
                                >
                                    <div id="getCTANewsletterHubspot"></div>
                                    {!submitted && (
                                        <div className="inputArrow">
                                            <svg
                                                width="12"
                                                height="11"
                                                viewBox="0 0 10 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.997559 4.00021H9.00205M9.00205 4.00021L5.8578 0.855957M9.00205 4.00021L5.8578 7.14447"
                                                    stroke="#fff"
                                                />{' '}
                                            </svg>
                                        </div>
                                    )}
                                </HubspotForm>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </FramerMotion>
        </section>
    )
}
